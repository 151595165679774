<template>
  <el-dialog
    v-model="showRuleDialog"
    width="1200px"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-costbasis-rule-dialog"
    destroy-on-close
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-costbasis-rule-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-form ref="ruleFormRef" label-position="top">
      <el-form-item :label="t('common.ruleName')" prop="name" :required="false">
        <el-input v-model="ruleName" :placeholder="t('message.pleaseInput')" class="elv-rule-form-name" />
      </el-form-item>

      <el-form-item :label="t('common.conditions')" class="elv-costbasis-rule-form-item-line condition">
        <div v-if="showRuleDialog" class="elv-rule-form-condition">
          <Conditions :condition-list="conditionList" @on-condition-change="onConditionChange" />
        </div>
      </el-form-item>
    </el-form>
    <div class="elv-costbasis-rule-dialog-add__button" @click="addCondition">
      <svgIcon name="add-default" width="16" height="16" fill="#1753EB" />{{
        t('button.addCustomButton', { name: 'Condition' })
      }}
    </div>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-costbasis-rule-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onSubmit"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Conditions from './Conditions.vue'
import { isEmpty, cloneDeep, toString } from 'lodash-es'
import ValuationApi from '@/api/ValuationApi'
import { ElMessage } from 'element-plus'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  title: {
    type: String,
    default: ''
  },
  currentRule: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['onSubmit'])

const { t } = useI18n()
const route = useRoute()

const ruleName = ref('')
const submitLoading = ref(false)
const showRuleDialog = ref(false)

const emptyCondition = {
  index: '0',
  saleField: '',
  inventoryField: '',
  saleAdditionItemPath: '',
  inventoryAdditionItemPath: ''
}

const conditionList = ref<any[]>([emptyCondition])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const onCloseDialog = () => {
  ruleName.value = ''
  conditionList.value = [emptyCondition]
}

const saveDisabled = computed(() => {
  let disabled = false

  if (!ruleName.value) {
    disabled = true
    return disabled
  }

  conditionList.value.forEach((item: any) => {
    if (!item.saleField || !item.inventoryField) {
      disabled = true
      return
    }

    if (item.saleField === 'ADDITIONAL_ITEM' && !item.saleAdditionItemPath) {
      disabled = true
      return
    }

    if (item.inventoryField === 'ADDITIONAL_ITEM' && !item.inventoryAdditionItemPath) {
      disabled = true
      return
    }
  })

  return disabled
})

// 切换弹窗展示
const onCheckRuleDialog = () => {
  showRuleDialog.value = !showRuleDialog.value
}

const addCondition = () => {
  if (conditionList.value.length < 51) {
    conditionList.value.push({
      ...emptyCondition,
      index: toString(Math.floor(Math.random() * 100000000))
    })
  }
}

// emit 事件
const onConditionChange = (index: number, newCondition: any, operating: string = 'edit') => {
  console.log(index, newCondition, operating)
  const modifiedIndex = conditionList.value.findIndex((item: any) => item.index === index)
  if (operating === 'edit') {
    conditionList.value[modifiedIndex] = newCondition
  } else {
    if (conditionList.value.length === 1) {
      return
    }
    conditionList.value.splice(modifiedIndex, 1)
  }
}

defineExpose({ onCheckRuleDialog })

const onSubmit = async () => {
  submitLoading.value = true

  const params = {
    name: ruleName.value,
    conditions: conditionList.value
  }

  try {
    if (props.model === 'add') {
      await ValuationApi.createSpecificIdRule(entityId.value, params)
    } else {
      await ValuationApi.updateSpecificIdRule(entityId.value, props.currentRule.specificRuleId, params)
    }
    emit('onSubmit')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    submitLoading.value = false
    showRuleDialog.value = false
  }
}

watch(
  () => props.currentRule,
  async () => {
    if (!isEmpty(props.currentRule)) {
      ruleName.value = props.currentRule.name
      conditionList.value = cloneDeep(props.currentRule.conditions)
    }
  },
  { deep: true }
)
</script>

<style lang="scss">
.elv-costbasis-rule-dialog {
  width: 1200px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0 24px !important;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    border-bottom: 1px solid #edf0f3;

    .elv-costbasis-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-form {
      width: 100%;
    }

    .el-input {
      // width: 802px;
      height: 36px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-costbasis-rule-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 704px;
            height: 1px;
            background-color: #dde1e6;
          }
        }

        &.condition .el-form-item__label::after {
          width: 1080px;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 100%;
        min-height: 52px;
        box-sizing: border-box;
        // padding: 8px;
        // background: #f9fafb;
        // border-radius: 5px;
        // :nth-last-child(1) {
        //   margin-bottom: 0px;
        // }
        .elv-costbasis-rule-conditions:last-child {
          margin-bottom: 0px;
        }

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }
      }

      .elv-rule-form-event {
        width: 100%;
        height: 94px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: #f9fafb;
        border-radius: 5px;

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }

        .elv-journal-type-drawer-category-select {
          box-sizing: border-box;
          height: 44px;
          width: 786px;
          padding: 10px 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #ffffff;
          border: 1px solid #dde1e6;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          position: relative;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition-property: border, box-shadow;

          > div {
            display: flex;
            flex-direction: column;

            p {
              font-family: 'Plus Jakarta Sans';
              font-weight: 400;
              font-size: 11px;
              line-height: 15px;
              color: #aaafb6;
              margin-bottom: 8px;
            }

            span {
              font-family: 'Plus Jakarta Sans';
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: #0e0f11;
            }
          }

          &:hover {
            cursor: pointer;
            border: 1px solid #5e85eb;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

            svg {
              fill: #5e85eb;
            }
          }

          svg {
            position: absolute;
            right: 12px;
            bottom: 14px;
            fill: #838d95;
            transition: transform 0.3s;
          }
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-journal-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}

.elv-costbasis-rule-dialog-add__button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1753eb;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
