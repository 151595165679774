<template>
  <div>
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData.list"
      border
      stripe
      :height="props.tableHeight"
      class="elv-valuation-record-table"
      header-cell-class-name="elv-valuation-record-table-header__cell"
      header-row-class-name="elv-valuation-record-table-header"
      row-class-name="elv-valuation-record-table-row"
      cell-class-name="elv-valuation-record-table-row__cell"
    >
      <el-table-column type="index" width="40" align="center" class-name="elv-valuation-record-table-row__cell-index">
        <template #default="{ row }">
          <span>{{ (row.rowIndex + 1) * props.params.page }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.purchaseNo')" width="110">
        <template #default="{ row }">
          <TextCell :text="row.purchaseNo" :weight="500" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.date')" width="125">
        <template #default="{ row }">
          <TextCell :text="formatDatetime(row.datetime)" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Journal')" width="190">
        <template #default="{ row }">
          <VerticalTextCell
            :up-text="row.journalType?.name"
            up-font-family="Plus Jakarta Sans"
            :down-text="row.journalActivity?.journalNo"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('valuation.crypto.referenceNo')"
        width="180"
        show-overflow-tooltip
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell :text="row.journalActivity?.referenceNo" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.currency')" width="150">
        <template #default="{ row }">
          <IconWithTextCell
            :icon="row.underlyingCurrency?.logo"
            :title="row.underlyingCurrency?.showSymbol"
            title-font-family="Barlow"
            :title-weight="400"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="t('valuation.crypto.quantity')"
        width="110"
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell
            :text="formatNumber(row.purchaseQuantity, 20)"
            :title-desc="formatNumber(row.purchaseQuantity, 20)"
            :weight="500"
            align="right"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.purchasePrice')" width="110">
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.purchasePrice,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :title-desc="
              fieldValueFormat(
                row.purchasePrice,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.costBasis')" width="110">
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.purchaseCost,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :title-desc="
              fieldValueFormat(
                row.purchaseCost,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.inventory')" min-width="110" fixed="right">
        <template #default="{ row }">
          <TextCell :text="row.inventoryNo" align="right" :weight="500" />
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-valuation-record-list-pagination"
      size-popper-class="elv-valuation-record-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/TextCell.vue'
import IconWithTextCell from '@/components/Base/Table/IconWithTitleCell.vue'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import { formatNumberToSignificantDigits, fieldValueFormat, formatNumber } from '@/lib/utils'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const emit = defineEmits(['onChangePage', 'onChangePageSize'])

const tableListRef = ref()

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const formatDatetime = (datetime: string) => {
  return dayjs(datetime)
    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
    .format('YYYY/MM/DD HH:mm')
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss"></style>
