<template>
  <el-dialog
    v-model="showSelectInventoryDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-select-inventory-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-select-inventory-dialog-header__title">
        {{ t('button.selectInventory') }}
      </h4>
    </template>

    <div v-loading="dialogLoading" class="elv-valuation-select-inventory-dialog-content">
      <div class="elv-valuation-select-inventory-inventory">
        <p class="elv-valuation-select-inventory-inventory-title">
          {{ t('project.costBasis.token') }}:
          <span>{{ props.currentData?.underlyingCurrency?.showSymbol ?? props.currentData?.currency }}</span>
        </p>
        <div class="elv-valuation-select-inventory-inventory-info">
          <p>
            {{ t('common.selected') }}: <span>{{ formatNumber(selectedQuantity, 20) }}</span>
          </p>
          <p>
            {{ t('common.difference') }}: <span>{{ formatNumber(differenceQuantity, 20) }}</span>
          </p>
        </div>
      </div>

      <el-table
        ref="tableListRef"
        :data="inventoryCandidateData.list ?? []"
        border
        stripe
        row-key="inventoryId"
        max-height="calc(100vh - 460px)"
        class="elv-valuation-record-table"
        header-cell-class-name="elv-valuation-record-table-header__cell"
        header-row-class-name="elv-valuation-record-table-header"
        row-class-name="elv-valuation-record-table-row"
        cell-class-name="elv-valuation-record-table-row__cell"
        @selection-change="onSelectionChange"
      >
        <el-table-column type="selection" width="40" reserve-selection />
        <el-table-column :label="t('valuation.crypto.inventoryNo')" width="110">
          <template #default="{ row }">
            <VerticalTextCell
              :up-text="row.inventoryNo"
              :up-weight="500"
              :down-text="
                dayjs(row?.datetime)
                  .tz(row?.journalActivity?.timezone ?? 'UTC')
                  .format('YYYY/MM/DD')
              "
              align="left"
            />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Journal')">
          <template #default="{ row }">
            <VerticalTextCell
              :up-text="row.journalType?.name"
              :up-weight="500"
              up-font-family="Plus Jakarta Sans"
              :down-text="row.journalActivity?.journalNo"
              align="left"
            />
          </template>
        </el-table-column>

        <el-table-column :label="t('valuation.crypto.quantity')" width="130">
          <template #default="{ row }">
            <TextCell :text="formatNumber(row?.purchaseQuantity, 20)" align="right" :weight="500" />
          </template>
        </el-table-column>

        <el-table-column :label="t('common.price')" width="130">
          <template #default="{ row }">
            <TextCell
              :text="
                formatNumberToSignificantDigits(
                  row.purchasePrice,
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              "
              :title-desc="
                fieldValueFormat(
                  row.purchasePrice,
                  {
                    price: true,
                    keepPoint: true,
                    symbol: `${
                      entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                    }`
                  },
                  'NUMBER'
                )
              "
              align="right"
              :weight="500"
            />
          </template>
        </el-table-column>

        <el-table-column :label="t('project.costBasis.remain')" width="130">
          <template #default="{ row }">
            <TextCell :text="formatNumber(row?.remainQuantity, 20)" align="right" :weight="500" />
          </template>
        </el-table-column>
      </el-table>

      <elv-pagination
        :limit="inventoryCandidateParams.limit"
        :current-page="inventoryCandidateParams.page"
        :total="inventoryCandidateData.total"
        @current-change="onChangePage"
        @size-change="onChangePageSize"
      />
    </div>

    <div class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        height="44"
        width="109"
        :loading="saveLoading"
        :disabled="differenceQuantity !== 0"
        @click="onSaveSelectInventory"
        >{{ t('common.select') }}</elv-button
      >
    </div>
  </el-dialog>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import Big from 'big.js'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/TextCell.vue'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const tableListRef = ref()
const dialogLoading = ref(false)
const saveLoading = ref(false)
const showSelectInventoryDialog = ref(false)
const emit = defineEmits(['resetList'])
const inventoryCandidateParams = ref({
  page: 1,
  limit: 20
})
const inventoryCandidateData: any = reactive({
  total: 0,
  list: []
})
const multipleSelection: any = ref([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const selectedQuantity = computed(() => {
  const selectedValue = multipleSelection.value.reduce((acc: Big, cur: any) => {
    return acc.plus(cur.remainQuantity ?? 0)
  }, Big(0))
  return selectedValue.toNumber()
})

const differenceQuantity = computed(() => {
  const differenceValue = Big(selectedQuantity.value).gte(props.currentData.soldQuantity)
    ? Big(0)
    : Big(props.currentData.soldQuantity).minus(selectedQuantity.value)

  return differenceValue.toNumber()
})

const onCheckSelectInventoryDialog = () => {
  showSelectInventoryDialog.value = !showSelectInventoryDialog.value
}

const onSelectionChange = (val: any[]) => {
  multipleSelection.value = val
}

const onSaveSelectInventory = async () => {
  try {
    saveLoading.value = true
    await ValuationApi.switchSaleInventoryToManual(entityId.value, {
      saleId: props.currentData.saleId,
      inventoryIds: multipleSelection.value.map((item: any) => item.inventoryId)
    })
    ElMessage.success(t('message.saveSuccess'))
    emit('resetList')
    showSelectInventoryDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const fetchInventoryCandidateList = async () => {
  try {
    dialogLoading.value = true
    const { data } = await ValuationApi.getInventoryCandidateList(entityId.value, {
      ...inventoryCandidateParams.value,
      saleId: props.currentData?.saleId
    })
    inventoryCandidateData.list = data.list
    inventoryCandidateData.total = data.total
    tableListRef.value?.setScrollTop(0)
  } catch (error: any) {
    console.log(error)
  } finally {
    dialogLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  inventoryCandidateParams.value.limit = limit
  inventoryCandidateParams.value.page = 1
  fetchInventoryCandidateList()
}

const onChangePage = (page: number) => {
  inventoryCandidateParams.value.page = page
  fetchInventoryCandidateList()
}

const onCloseDialog = () => {
  tableListRef.value?.clearSelection()
  multipleSelection.value = []
}

defineExpose({ onCheckSelectInventoryDialog })

watch(
  [() => showSelectInventoryDialog.value, () => props.currentData],
  async () => {
    if (showSelectInventoryDialog.value && !isEmpty(props.currentData)) {
      await fetchInventoryCandidateList()
    }
  },
  { immediate: true, flush: 'post' }
)
</script>

<style lang="scss">
.elv-valuation-select-inventory-dialog {
  width: 980px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-valuation-select-inventory-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .elv-valuation-select-inventory-dialog-content {
    min-height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .elv-valuation-select-inventory-inventory {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .elv-valuation-select-inventory-inventory-title {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 8px;
        }
      }

      .elv-valuation-select-inventory-inventory-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        p {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    width: 100%;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }
  }
}
</style>
