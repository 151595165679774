<template>
  <el-table
    ref="tableElRef"
    :row-key="'index'"
    :data="conditionList"
    style="width: 100%"
    max-height="calc(100vh - 440px)"
    border
    class="elv-costbasis-rule-conditions-table"
  >
    <el-table-column :label="`${t('valuation.crypto.sales')}:`" class-name="normal-padding">
      <template #default="{ row }">
        <el-select
          :model-value="row.saleField"
          popper-class="elv-costbasis-rule-conditions-input-popper"
          class="elv-costbasis-rule-conditions-type"
          :placeholder="t('common.selectCondition')"
          @change="(value: string) => onChangeSaleField(row, value)"
        >
          <el-option v-for="(item, i) in saleOptions" :key="i" :label="transformI18n(item.label)" :value="item.value" />
        </el-select>
        <el-input
          v-if="row.saleField === 'ADDITIONAL_ITEM'"
          :model-value="row.saleAdditionItemPath"
          class="elv-costbasis-rule-conditions-short-input"
          @input="(value: string) => onChangeSaleAdditionItemPath(row, value)"
        />
      </template>
    </el-table-column>
    <el-table-column :label="`${t('valuation.tolerance')}:`" width="320px" class-name="normal-padding">
      <template #default>
        <el-select
          :model-value="'EQ'"
          popper-class="elv-costbasis-rule-conditions-input-popper"
          class="elv-costbasis-rule-conditions-type"
          disabled
        >
          <el-option
            v-for="(item, i) in toleranceOptions"
            :key="i"
            :label="item.label"
            :value="item.value"
            :disabled="true"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column :label="`${t('valuation.crypto.inventory')}:`" class-name="normal-padding">
      <template #default="{ row }">
        <el-select
          :model-value="row.inventoryField"
          popper-class="elv-costbasis-rule-conditions-input-popper"
          class="elv-costbasis-rule-conditions-type"
          :placeholder="t('common.selectCondition')"
          @change="(value: string) => onChangeInventoryField(row, value)"
        >
          <el-option
            v-for="(item, i) in inventoryOptions"
            :key="i"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
        <el-input
          v-if="row.inventoryField === 'ADDITIONAL_ITEM'"
          :model-value="row.inventoryAdditionItemPath"
          class="elv-costbasis-rule-conditions-short-input"
          @input="(value: string) => onChangeInventoryAdditionItemPath(row, value)"
        />
      </template>
    </el-table-column>
    <el-table-column class-name="normal-padding" width="44">
      <template #default="{ row }">
        <SvgIcon
          name="sources-delete"
          width="18"
          height="18"
          class="elv-costbasis-rule-conditions__delete"
          @click="onDeleteCondition(row.index)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { saleOptions, inventoryOptions } from '@/config/valuation'

defineProps({
  conditionList: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['onConditionChange'])

const { t } = useI18n()

const toleranceOptions = [
  {
    label: 'Equals',
    value: 'EQ'
  }
]

const onChangeSaleField = (item: any, value: string) => {
  emit('onConditionChange', item.index, { ...item, saleField: value })
}

const onChangeInventoryField = (item: any, value: string) => {
  emit('onConditionChange', item.index, { ...item, inventoryField: value })
}

const onChangeSaleAdditionItemPath = (item: any, value: string) => {
  emit('onConditionChange', item.index, { ...item, saleAdditionItemPath: value })
}

const onChangeInventoryAdditionItemPath = (item: any, value: string) => {
  emit('onConditionChange', item.index, { ...item, inventoryAdditionItemPath: value })
}

const onDeleteCondition = (index: number) => {
  emit('onConditionChange', index, null, 'delete')
}
</script>

<style lang="scss">
.elv-costbasis-rule-conditions-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ced7e0;
  border-radius: 3px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #f9fafb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &:last-child {
        border-right: 0px;
      }

      &.is-center-label {
        .cell {
          justify-content: center;
        }
      }

      .cell {
        padding: 0 8px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      width: 0;
      height: 0;
      display: none;
    }

    .el-table__inner-wrapper {
      &::after {
        background-color: #ced7e0;
      }
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 18px;
    border-radius: 20px;
  }

  .el-table__row {
    &:not(.elv-rule-table-is-disabled) {
      &.elv-table__row--striped {
        background: #f9fafb;
      }

      &.is-hover-row {
        background: #f5f7fa;
      }

      &:hover td {
        background: transparent;
      }
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        padding: 0 10px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;

        .elv-sources-rule-table-row__cell-action__title {
          color: #000;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .elv-sources-rule-table-row__cell-action__label {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
        }

        .elv-sources-rule-table-cell-conditions__item-is {
          margin: 0 6px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          color: #636b75;

          span:nth-child(2) {
            margin-left: 4px;
          }
        }

        .elv-sources-rule-table-row__cell-action__value {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      &.normal-padding {
        .cell {
          padding: 8px;
        }
      }
    }
  }

  .elv-sources-rule-table-row__cell-index {
    color: #aaafb6;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-sources-rule-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-sources-rule-table-row__cell-data {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-sources-rule-table-row__cell-action {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-sources-rule-table-cell-action__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-sources-rule-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-sources-rule-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-sources-rule-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  .elv-sources-rule-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-sources-rule-table-row__cell-reconciliation {
    display: flex;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    p {
      color: #0e0f11;
      margin-left: 4px;
      font-weight: 500;
    }
  }

  .elv-sources-rule-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-select .el-select__wrapper {
    height: 36px;
  }

  .elv-sources-rule-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      width: 18px;
      height: 18px;
      display: block;
      animation: loading-rotate 2s linear infinite;
    }

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #edf0f3 !important;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }

  .elv-costbasis-rule-conditions-short-input {
    margin-left: 8px;
  }

  .elv-costbasis-rule-conditions__delete {
    cursor: pointer;
  }
}
</style>
