<template>
  <div class="elv-costbasis-rule-table-row__cell-conditions">
    <div v-if="props.params.specificRuleId == -1" class="elv-costbasis-rule-table-cell-conditions__item">
      <span class="elv-costbasis-rule-table-row__cell-action__label"> {{ t('valuation.fifoCondition') }} </span>
    </div>
    <div
      v-for="(condition, index) in props.params.conditions"
      v-else
      :key="index"
      class="elv-costbasis-rule-table-cell-conditions__item"
    >
      <span class="elv-costbasis-rule-table-row__cell-action__label">
        {{ getSaleValue(condition) }}
      </span>
      <p class="elv-costbasis-rule-table-cell-conditions__item-is">
        <span> = </span>
      </p>
      <div class="elv-costbasis-rule-table-cell-conditions__item-value">
        {{ getInventoryValue(condition) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { saleOptions, inventoryOptions } from '@/config/valuation'
import { transformI18n } from '@/i18n/index'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()

const getSaleValue = (condition: any) => {
  const saleName1 = transformI18n(saleOptions.find((item) => item.value === condition.saleField)?.label)
  const saleName2 = condition.saleField === 'ADDITIONAL_ITEM' ? condition.saleAdditionItemPath : ''
  const result = [saleName1]
  if (saleName2) {
    result.push(saleName2)
  }
  return result.join('.')
}

const getInventoryValue = (condition: any) => {
  const inventoryName1 = transformI18n(inventoryOptions.find((item) => item.value === condition.inventoryField)?.label)
  const inventoryName2 = condition.inventoryField === 'ADDITIONAL_ITEM' ? condition.inventoryAdditionItemPath : ''
  const result = [inventoryName1]
  if (inventoryName2) {
    result.push(inventoryName2)
  }
  return result.join('.')
}
</script>

<style lang="scss" scoped>
.elv-costbasis-rule-table-row__cell-conditions {
  padding: 11px 0;
  display: flex;
  flex-direction: column;

  .elv-costbasis-rule-table-cell-conditions__item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .elv-costbasis-rule-table-cell-conditions__item-type {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;
  }

  .elv-costbasis-rule-table-cell-conditions__item-is {
    margin-right: 6px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #636b75;

    span {
      white-space: nowrap;
    }

    span:nth-child(2) {
      margin-left: 4px;
    }
  }

  .elv-costbasis-rule-table-cell-conditions__item-value {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 18px;

    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.5px;
    white-space: nowrap;

    svg,
    img {
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }

    svg {
      fill: #1343bf;
    }
  }

  .elv-costbasis-rule-table-row__cell-action__value {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 4px;
  }
}
</style>
