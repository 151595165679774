<template>
  <div class="elv-costbasis-method-dialog">
    <div class="header">
      <h4>{{ t('title.setCostBasisMethod') }}</h4>
      <div class="desc">{{ t('valuation.selectMethod') }}</div>
    </div>
    <div class="select">
      <el-select v-model="method" :teleported="false" class="is-shorter-entry" :popper-append-to-body="false">
        <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <RuleContent v-if="method === 'SPECIFIC_ID'" />
    <div class="button">
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="submitting"
        :disabled="entityStore.entityDetail?.inventoryCostBasisMethodology === method"
        @click="onSubmit"
        >{{ t('button.save') }}</elv-button
      >
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import RuleContent from './RuleContent/Index.vue'
import { useEntityStore } from '@/stores/modules/entity'

const emit = defineEmits(['onCloseDialog'])

const { t } = useI18n()
const entityStore = useEntityStore()

const submitting = ref(false)
const method = ref(entityStore.entityDetail?.inventoryCostBasisMethodology)
const selectOptions = [
  {
    value: 'FIFO',
    label: t('valuation.fifo')
  },
  {
    value: 'COST_AVERAGE',
    label: t('valuation.average')
  },
  {
    value: 'SPECIFIC_ID',
    label: t('valuation.specificID')
  }
]

const onSubmit = async () => {
  submitting.value = true
  try {
    await ValuationApi.changeInventoryCostBasisMethodology(entityStore.entityDetail?.entityId, {
      inventoryCostBasisMethodology: method.value
    })
    emit('onCloseDialog')
    await entityStore.fetchEntityDetail(entityStore.entityDetail?.entityId)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    submitting.value = false
  }
}
</script>
<style lang="scss" scoped>
.elv-costbasis-method-dialog {
  padding: 23px;

  .header {
    margin-bottom: 16px;

    h4 {
      text-align: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      margin-bottom: 2px;
    }

    .desc {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .select {
    margin-bottom: 16px;
  }

  .button {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
}
</style>
